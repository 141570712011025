import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { verifyOTP, sendOTP } from '../../utils/api';
import styles from '../../styles/UniversityQuizAppStyles/OTPVerification.module.css';

const OTPVerification = ({ phone, onVerificationComplete, className = '' }) => {
  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState(['', '', '', '']);
  const [cooldown, setCooldown] = useState(0);
  const [attempts, setAttempts] = useState(3);
  const [smsAttempts, setSmsAttempts] = useState(3); // Total SMS attempts allowed
  const [isSubmitting, setIsSubmitting] = useState(false); // Added state for verify button loading
  const [isResending, setIsResending] = useState(false); // Added state for resend button loading
  const [error, setError] = useState('');
  const COOLDOWN_TIME = 60; // 1 minute cooldown
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const resendTimeoutRef = useRef(null);
  const submitTimeoutRef = useRef(null);
  const hasInitiatedOTP = useRef(false);

  const startCooldown = () => {
    setCooldown(COOLDOWN_TIME);
    
    if (resendTimeoutRef.current) {
      clearInterval(resendTimeoutRef.current);
    }
    
    resendTimeoutRef.current = setInterval(() => {
      setCooldown(prev => {
        if (prev <= 1) {
          clearInterval(resendTimeoutRef.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    // Send OTP when component mounts - only once
    const initiateOTP = async () => {
      if (hasInitiatedOTP.current) return;
      
      hasInitiatedOTP.current = true;
      try {
        await sendOTP(phone, i18n.language);
      } catch (err) {
        console.error('Error sending OTP:', err);
        setError(t('otp.sendError'));
        hasInitiatedOTP.current = false; // Reset if there was an error
      }
    };
    
    initiateOTP();
    startCooldown(); // Start initial cooldown
    
    return () => {
      if (resendTimeoutRef.current) clearInterval(resendTimeoutRef.current);
      if (submitTimeoutRef.current) clearTimeout(submitTimeoutRef.current);
    };
  }, [phone, t, i18n.language]);

  const handleInputChange = (index, value) => {
    if (!/^\d*$/.test(value)) return; // Only allow digits

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setError('');

    // Auto-focus next input
    if (value && index < 3) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 4).split('');
    const newOtp = [...otp];
    pastedData.forEach((char, index) => {
      if (/^\d$/.test(char) && index < 4) {
        newOtp[index] = char;
      }
    });
    setOtp(newOtp);
    if (newOtp[3]) inputRefs[3].current.focus();
  };

  const handleSubmit = async () => {
    // Important: Exit early if already submitting to prevent multiple calls
    if (isSubmitting) return;
    
    // Set submitting state immediately
    setIsSubmitting(true);
    setError('');

    const otpString = otp.join('');
    if (otpString.length !== 4) {
      setError(t('otp.invalidCodeLength'));
      setIsSubmitting(false);
      return;
    }

    try {
      // Make the API call and await its response
      const response = await verifyOTP(phone, otpString);
      
      // Process the response only if we're still mounted/relevant
      if (response?.data?.is_verified) {
        // Maintain the loading state while transitioning to the next screen
        // The loading state will be handled by the parent component now
        onVerificationComplete();
      } else {
        const newAttempts = attempts - 1;
        setAttempts(newAttempts);
        setError(t('otp.invalidCode'));
        
        setOtp(['', '', '', '']);
        inputRefs[0].current.focus();
        // Only clear submitting state if verification failed
        setIsSubmitting(false);
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      const newAttempts = attempts - 1;
      setAttempts(newAttempts);
      setError(err.response?.data?.error || t('otp.verificationError'));
      
      setOtp(['', '', '', '']);
      inputRefs[0].current.focus();
      // Clear submitting state on error
      setIsSubmitting(false);
    }
    // Note: we're not using finally here because we want to keep isSubmitting true on success
  };

  const handleResendCode = async () => {
    // Exit early if already processing, in cooldown, or no SMS attempts left
    if (cooldown > 0 || smsAttempts <= 1 || isResending) return;
    
    // Set resending state immediately
    setIsResending(true);
    
    try {
      // Make the API call and await its response
      await sendOTP(phone, i18n.language);
      
      // Process response
      setSmsAttempts(prev => prev - 1); // Decrease SMS attempts
      setAttempts(3); // Reset verification attempts
      startCooldown();
      setOtp(['', '', '', '']);
      setError('');
      inputRefs[0].current.focus();
    } catch (err) {
      console.error('Error resending OTP:', err);
      setError(t('otp.resendError'));
    } finally {
      // Always clear resending state when done
      setIsResending(false);
    }
  };

  // If no more attempts and SMS attempts left, show final error
  if (attempts <= 0 && smsAttempts <= 1) { // Keep 1 for current attempt
    return (
      <div className={`${styles.otpContainer} ${className}`}>
        <div className={styles.otpHeader}>
          <h2>{t('otp.title')}</h2>
          <p className={styles.errorText}>
            {t('otp.maxAttemptsReached')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={`${styles.otpContainer} ${className}`}>
      <div className={styles.otpHeader}>
        <h2>{t('otp.title')}</h2>
        <p>{t('otp.sentTo')} {phone}</p>
      </div>

      <div className={styles.otpInputContainer}>
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="number"
            maxLength={1}
            value={digit}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            className={styles.otpInput}
            disabled={isSubmitting} // Disable inputs while submitting
          />
        ))}
      </div>

      {error && (
        <p className={styles.errorText}>{error}</p>
      )}

      <button
        onClick={handleSubmit}
        disabled={isSubmitting || otp.some(digit => !digit) || attempts <= 0}
        className={styles.verifyButton}
      >
        {isSubmitting ? (
          <>
            <div className={styles.buttonSpinner}></div>
            <span>{t('otp.verifying') || 'Verifying...'}</span>
          </>
        ) : (
          t('otp.verify')
        )}
      </button>

      <div className={styles.otpFooter}>
        <p className={styles.attemptsText}>
          {t('otp.attemptsLeft', { count: attempts })}
        </p>
        <p className={styles.attemptsText}>
          {t('otp.smsAttemptsLeft', { count: smsAttempts - 1 })} {/* Subtract 1 to not count current attempt */}
        </p>
        {cooldown > 0 ? (
          <p className={styles.cooldownText}>
            {t('otp.resendIn', { time: `${Math.floor(cooldown / 60)}:${(cooldown % 60).toString().padStart(2, '0')}` })}
          </p>
        ) : (
          // Show resend button if we have SMS attempts left and cooldown is done
          smsAttempts > 1 && (
            <button
              onClick={handleResendCode}
              disabled={isResending}
              className={styles.resendButton}
            >
              {isResending ? (
                <>
                  <div className={styles.smallSpinner}></div>
                  <span>{'Resending...'}</span>
                </>
              ) : (
                t('otp.resendCode')
              )}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default OTPVerification;