import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'lucide-react';
import { submitQuizResults, checkPhoneIsVerified } from '../../utils/api';
import PhoneInput from './PhoneInput';
import OTPVerification from './OTPVerification';
import styles from '../../styles/UniversityQuizAppStyles/UniversityQuiz.module.css';

const AnimatedBackground = ({ 
  primaryColor = '#ffffff',
  secondaryColor = '#000000',
  opacity = 0.2  // This is the base opacity that will be multiplied
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let particles = [];
    
    const setCanvasSize = () => {
      const dpr = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * dpr;
      canvas.height = window.innerHeight * dpr;
      ctx.scale(dpr, dpr);
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    class Particle {
      constructor() {
        this.x = Math.random() * window.innerWidth;
        this.y = Math.random() * window.innerHeight;
        // Slightly increased speed for more dynamic movement
        this.vx = (Math.random() - 0.5) * 0.2;
        this.vy = (Math.random() - 0.5) * 0.2;
      }

      update() {
        this.x += this.vx;
        this.y += this.vy;

        if (this.x < 0 || this.x > window.innerWidth) this.vx *= -1;
        if (this.y < 0 || this.y > window.innerHeight) this.vy *= -1;
      }
    }

    const createParticles = () => {
      const isMobile = window.innerWidth <= 768;
      // Increased number of particles
      const numberOfParticles = isMobile ? 40 : 65;
      particles = Array.from({ length: numberOfParticles }, () => new Particle());
    };

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      ctx.globalAlpha = 1;
      ctx.fillStyle = primaryColor;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      particles.forEach(particle => {
        particle.update();
      });

      // Draw connections with increased visibility
      const isMobile = window.innerWidth <= 768;
      ctx.lineWidth = isMobile ? 1.5 : 2; // Increased line width
      ctx.strokeStyle = secondaryColor;
      
      // Increased connection distance and base opacity
      const connectionDistance = isMobile ? 150 : 200;
      const baseOpacity = opacity * 2; // Doubled the base opacity

      particles.forEach((particle, i) => {
        particles.slice(i + 1).forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < connectionDistance) {
            ctx.beginPath();
            // Adjusted opacity calculation for better visibility
            const lineOpacity = (1 - distance / connectionDistance) * baseOpacity;
            ctx.globalAlpha = Math.min(lineOpacity, 0.8); // Cap maximum opacity
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });

      // Draw particles with increased size and opacity
      const dotSize = isMobile ? 2.5 : 3;
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, dotSize, 0, Math.PI * 2);
        ctx.fillStyle = secondaryColor;
        ctx.globalAlpha = baseOpacity;
        ctx.fill();
      });

      animationFrameId = requestAnimationFrame(animate);
    };

    createParticles();
    animate();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      cancelAnimationFrame(animationFrameId);
    };
  }, [primaryColor, secondaryColor, opacity]);

  return (
    <div className={styles.animatedBackground}>
      <canvas
        ref={canvasRef}
        className={styles.backgroundCanvas}
      />
    </div>
  );
};

// Main Quiz Component
const UniversityQuiz = ({ 
  universityData, 
  logoUrl, 
  onQuizComplete, 
  startTime,
  professionProfiles 
}) => {
  const { t, i18n } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [profilePoints, setProfilePoints] = useState({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState({ display: '', value: '' });
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [answerHistory, setAnswerHistory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [sendToBitrix, setSentToBitrix] = useState(false)

  const questions = universityData?.questions || [];

  const handleAnswer = (questionId, variantId, variantText, professionProfilePoints) => {
    setAnswerHistory(prev => [...prev, {
      questionIndex: currentQuestionIndex,
      answers: { ...answers },
      profilePoints: { ...profilePoints }
    }]);

    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: {
        variantId,
        variantText,
        language: i18n.language
      }
    }));

    if (professionProfilePoints && typeof professionProfilePoints === 'object') {
      setProfilePoints(prevPoints => {
        const newPoints = { ...prevPoints };
        Object.entries(professionProfilePoints).forEach(([profile, points]) => {
          if (profile && !isNaN(points)) {
            newPoints[profile] = (newPoints[profile] || 0) + points;
          }
        });
        return newPoints;
      });
    }

    if (currentQuestionIndex === questions.length - 1) {
      setQuizCompleted(true);
    } else {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleGoBack = () => {
    if (answerHistory.length === 0) return;
    
    const previousState = answerHistory[answerHistory.length - 1];
    setCurrentQuestionIndex(previousState.questionIndex);
    setAnswers(previousState.answers);
    setProfilePoints(previousState.profilePoints);
    setAnswerHistory(prev => prev.slice(0, -1));
  };

  const validatePhone = (phone) => {
    const cleanPhone = phone.replace(/[^\d+]/g, '');
    const phoneRegex = /^(\+998|998)?(9[0123456789]|6[125679]|7[01234569])[0-9]{7}$/;
    return phoneRegex.test(cleanPhone);
  };

  const handleUserDetailsSubmit = async (e) => {
    e.preventDefault();
    
    if (!userName || !userPhone.value) {
      setPhoneError(t('quiz.userInfoForm.formError'));
      return;
    }
  
    if (!validatePhone(userPhone.value)) {
      setPhoneError(t('quiz.userInfoForm.phoneError'));
      return;
    }
  
    // Set submitting state before API call
    setIsSubmitting(true);
  
    try {
      const response = await checkPhoneIsVerified(userPhone.value);
      
      if (response?.data?.is_verified) {
        // Keep the isSubmitting state active during the transition
        await handleVerificationComplete(sendToBitrix);
        // Note: We don't set isSubmitting to false here because we're transitioning away
      } else {
        setShowOtpVerification(true);
        setSentToBitrix(true);
        // Only set isSubmitting to false if we stay on the form
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error checking phone verification:', error.response?.data || error);
      setPhoneError('Error submitting form. Please try again.');
      // Reset submitting state on error
      setIsSubmitting(false);
    }
  };

  const handleVerificationComplete = async (sendToBitrix) => {
    try {
      const formattedAnswers = {};
      const questionIds = [];
      const answerTexts = [];
  
      Object.entries(answers).forEach(([questionId, answerData]) => {
        questionIds.push(questionId);
        answerTexts.push(answerData.variantText);
        formattedAnswers[questionId] = {
          text: answerData.variantText,
          language: answerData.language
        };
      });
  
      const requestData = {
        question_ids: questionIds,
        answer_texts: answerTexts,
        answers_with_language: formattedAnswers,
        name: userName,
        phone: userPhone.value,
        test_started_at: startTime?.toISOString(),
        profile_points: profilePoints,
        language: i18n.language,
        is_completed: true
      };
      
      
      const response = await submitQuizResults(requestData, sendToBitrix);
  
      if (response?.data) {
        onQuizComplete(response.data);
      } else {
        throw new Error('No response data received');
      }
    } catch (error) {
      setPhoneError(t('quiz.userInfoForm.submitError'));
      setShowOtpVerification(false); // Go back to form on error
    }
  };
  
  const currentQuestion = questions[currentQuestionIndex];
  const totalQuestions = questions.length;
  const progress = ((currentQuestionIndex + 1) / totalQuestions) * 100;

  return (
    <div className={styles.quizWrapper}>
      <AnimatedBackground 
        primaryColor={universityData?.design?.primary_color || '#ffffff'}
        secondaryColor={universityData?.design?.secondary_color || '#000000'}
        opacity={0.1}
      />
      
      <div className={styles.quizContainer}>
        <header className={styles.header}>
          <a href={`${universityData.universityWebsite}`}>
            {logoUrl && <img src={logoUrl} alt={`${universityData.universityName} logo`} className={styles.logo} />}
          </a>
          {!quizCompleted && (
            <div className={styles.progressWrapper}>
              <div className={styles.progressBar}>
                <div 
                  className={styles.progressFill} 
                  style={{ 
                    width: `${progress}%`,
                    backgroundColor: universityData.design?.secondary_color 
                  }}
                />
              </div>
            </div>
          )}
          {!quizCompleted && (
            <span className={styles.questionCount} style={{ color: universityData.design?.secondary_color }}>
              {t('quiz.questionCount', { current: currentQuestionIndex + 1, total: totalQuestions })}
            </span>
          )}
        </header>
        
        <main className={styles.quizContent}>
          {!quizCompleted ? (
            <>
              <h2 className={styles.questionText} style={{ color: universityData.design?.secondary_color }}>
                {currentQuestion?.question_text?.[i18n.language]}
              </h2>
              <div className={styles.answerOptions}>
                {currentQuestion?.variants?.map((variant) => (
                  <button
                    key={variant.id}
                    onClick={() => handleAnswer(
                      currentQuestion.id, 
                      variant.id, 
                      variant.variant_text?.[i18n.language],
                      variant.profession_profile_points
                    )}
                    className={styles.answerButton}
                    style={{ 
                      color: universityData.design?.secondary_color,
                      borderColor: universityData.design?.secondary_color
                    }}
                  >
                    <span>{variant.variant_text?.[i18n.language]}</span>
                  </button>
                ))}
                {currentQuestionIndex > 0 && (
                  <button
                    key={currentQuestionIndex}
                    onClick={handleGoBack}
                    className={styles.backButton}
                    style={{ 
                      backgroundColor: universityData.design?.secondary_color,
                      color: universityData.design?.primary_color,
                      borderColor: universityData.design?.secondary_color
                    }}
                  >
                    <ArrowLeft size={20} />
                    <span>{t('quiz.backButton')}</span>
                  </button>
                )}
              </div>
            </>
          ) : (
            !showOtpVerification ? (
              <form onSubmit={handleUserDetailsSubmit} className={styles.userInfoForm}>
                <h2 style={{ color: universityData.design?.secondary_color }}>
                  {t('quiz.userInfoForm.title')}
                </h2>
                <input
                  type="text"
                  placeholder={t('quiz.userInfoForm.namePlaceholder')}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                  className={styles.input}
                />
                <PhoneInput
                  value={userPhone}
                  onChange={setUserPhone}
                  error={phoneError}
                  className={styles.input}
                />
                {phoneError && <p className={styles.errorText}>{phoneError}</p>}
                <button 
                  type="submit"
                  disabled={isSubmitting} 
                  className={styles.submitButton}
                  style={{
                    backgroundColor: universityData.design?.secondary_color,
                    color: '#ffffff'
                  }}
                >
                  {isSubmitting ? (
                    <>
                      <div className={styles.buttonSpinner}></div>
                      <span>{t('quiz.userInfoForm.submitting') || 'Submitting...'}</span>
                    </>
                  ) : (
                    t('quiz.userInfoForm.submitButton')
                  )}
                </button>
                <p
                  style={{ color: universityData.design?.secondary_color }} 
                  className={styles.confidentialityPolicy}
                  >
                    {t('quiz.userInfoForm.confidentialityPolicyBegin', {submitButtonText: t('quiz.userInfoForm.submitButton')})}
                    <a href="#">{t('quiz.userInfoForm.confidentialityPolicyLink')}</a>
                    {t('quiz.userInfoForm.confidentialityPolicyEnd')}
                </p>
              </form>
            ) : (
              <OTPVerification
                phone={userPhone.value}
                onVerificationComplete={() => handleVerificationComplete(sendToBitrix)}
                className={styles.userInfoForm}
              />
            )
          )}
        </main>

        <footer className={styles.footer}>
          <div className={styles.poweredBy}>
            <a href='https://workxplorer.uz'>
              {t('common.poweredBy')}
              <img src="/logo192.webp" alt="WorkXplorer logo" className={styles.workxplorerIcon} />
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default UniversityQuiz;