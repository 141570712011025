// StartQuiz.jsx
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/UniversityQuizAppStyles/StartQuiz.module.css';
import InstructionsModal from './InstructionsModal';

const StartQuiz = ({ universityData, logoUrl, onStartQuiz, availableLanguages }) => {
  const { t, i18n } = useTranslation();
  const [showInstructions, setShowInstructions] = useState(false);

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const handleStartClick = () => {
    setShowInstructions(true);
  };

  const handleInstructionsClose = () => {
    setShowInstructions(false);
  };

  const handleUserReady = () => {
    setShowInstructions(false);
    onStartQuiz();
  };

  const secondaryColor = universityData?.design?.secondary_color || '#000000';
  const primaryColor = universityData?.design?.primary_color || '#FFFFFF';
  const tertiaryColor = universityData?.design?.tertiary_color || '#FF0000';

  return (
    <div className={styles.startQuizContainer} style={{ backgroundColor: primaryColor }}>
      <div className={`${styles.decorativeCircle} ${styles.topCircle}`} style={{ borderColor: secondaryColor }}></div>
      <div className={`${styles.decorativeCircle} ${styles.bottomCircle}`} style={{ borderColor: secondaryColor }}></div>
      
      <div className={styles.mainContent}>
        <div className={styles.headerSection}>
          <a href={universityData.universityWebsite} target="_blank" rel="noopener noreferrer">
            {logoUrl && (
              <img 
                src={logoUrl} 
                alt={`${universityData.universityName} logo`} 
                className={styles.logo} 
              />
            )}
          </a>
          {/*
          <div 
            className={styles.languageSelectorWrapper} 
            style={{ 
              borderColor: secondaryColor 
            }}
          >
            
            <select 
              id="languageSelect"
              value={i18n.language} 
              onChange={handleLanguageChange} 
              className={styles.nativeSelect}
            >
              {availableLanguages.map((lang) => (
                <option key={lang} value={lang}>
                  {lang.toUpperCase()}
                </option>
              ))}
            </select>
            
            
            <div 
              className={styles.customSelector}
              onClick={() => {
                const select = document.getElementById('languageSelect');
                if (select) {
                  select.focus();
                  
                  // Create and dispatch a mouse event to simulate click
                  const event = new MouseEvent('mousedown', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                  });
                  select.dispatchEvent(event);
                }
              }}
              style={{ color: secondaryColor }}
            >
              <span className={styles.selectedValue}>{i18n.language.toUpperCase()}</span>
              <span className={styles.arrowIcon}>▼</span>
            </div>
          </div>*/}
        </div>
        

        <div className={styles.contentSection}>
          <div className={styles.titleRow}>
            <h1 className={styles.mainTitle} style={{ color: secondaryColor }}>
              {t('startQuiz.title')}
            </h1>
            <h3 className={styles.tagline} style={{ color: secondaryColor }}>
              {t('startQuiz.tagline.first')}{' '}
              <span style={{ color: tertiaryColor }}>{t('startQuiz.tagline.second')}</span>
              {' '}{t('startQuiz.tagline.third')}
            </h3>
          </div>

          <div className={styles.infoBoxContainer}>
            <div 
              className={styles.infoBox} 
              style={{ backgroundColor: secondaryColor, color: primaryColor }}
            >
              <div className={styles.infoTitle}>{t('startQuiz.infoBox.title1')}</div>
              <div className={styles.infoText}>
                {t('startQuiz.infoBox.text1')}
              </div>
            </div>
            
            <div 
              className={styles.infoBox} 
              style={{ backgroundColor: secondaryColor, color: primaryColor }}
            >
              <div className={styles.infoTitle}>{t('startQuiz.infoBox.title2')}</div>
              <div className={styles.infoText}>
                {t('startQuiz.infoBox.text2')}
              </div>
            </div>
          </div>

          <div className={styles.descriptionRow}>
            <p className={styles.description} style={{ color: secondaryColor }}>
              {t('startQuiz.description')}
            </p>
            <button 
              className={styles.startButton}
              onClick={handleStartClick}
              style={{color: primaryColor, backgroundColor: tertiaryColor}}
            >
              {t('startQuiz.startButton')}
            </button>
          </div>
        </div>
      </div>

      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
          <a href='https://workxplorer.uz'>
            {t('common.poweredBy')}
            <img src="/logo192.webp" alt="WorkXplorer logo" className={styles.workxplorerIcon} />
          </a>
        </div>
      </footer>

      {showInstructions && (
        <InstructionsModal 
          onClose={handleInstructionsClose}
          onStart={handleUserReady}
        />
      )}
    </div>
  );
};

export default StartQuiz;