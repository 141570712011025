import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
} from 'recharts';
import styles from '../../styles/UniversityQuizAppStyles/ResultPage.module.css';
import ProfessionModal from './ProfessionModal';

const ResponsiveRadarChart = ({ data, secondaryColor }) => {
  const CustomizedAxisTick = ({ payload, x, y }) => {
    const maxCharsPerLine = window.innerWidth <= 480 ? 8 : 12;
    const words = payload.value.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach(word => {
      if ((currentLine + ' ' + word).length <= maxCharsPerLine) {
        currentLine += (currentLine ? ' ' : '') + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });
    if (currentLine) {
      lines.push(currentLine);
    }

    return (
      <g>
        {lines.map((line, index) => (
          <text
            key={index}
            x={x}
            y={y + (index * 12)}
            textAnchor="middle"
            fontSize={window.innerWidth <= 480 ? "10" : "12"}
            fill="#1F2937"
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius="80%"
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      >
        <PolarGrid 
          stroke={secondaryColor}
          opacity={0.28}
        />
        <PolarAngleAxis
          dataKey="subject"
          tick={<CustomizedAxisTick />}
          stroke={secondaryColor}
          opacity={0.28}
        />
        <PolarRadiusAxis
          axisLine={false}
          tick={false}
        />
        <Radar
          name="Profile"
          dataKey="score"
          stroke={secondaryColor}
          fill={secondaryColor}
          fillOpacity={0.3}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const ResultPage = ({
  quizResults,
  universityData,
  secondaryLogoUrl
}) => {
  const { t } = useTranslation();
  const [selectedProfession, setSelectedProfession] = useState(null);
  const secondaryColor = universityData?.design?.secondary_color || '#02225B';
  
  const getProfileResults = () => {
    if (!quizResults?.profile_results) return [];
    
    return quizResults.profile_results
      .sort((a, b) => b.score - a.score)
      .slice(0, 4)
      .map(profile => ({
        name: profile.name,
        score: profile.score
      }));
  };

  const generateChartData = () => {
    if (!quizResults?.profile_results) return [];
    
    return quizResults.profile_results.map(profile => ({
      subject: profile.name,
      score: profile.score
    }));
  };

  const renderProfessionCards = () => {
    if (!quizResults?.recommended_profiles?.length) return [];
    
    return quizResults.recommended_profiles.flatMap(profile => 
      profile.professions?.map((profession, index) => (
        <div 
          key={`${profile.name}-${index}`} 
          className={styles.professionCard}
        >
          <h3 className={styles.professionName}>
            {profession.name}
          </h3>
          <p>{profession.description}</p>
          <div className={styles.salaryInfo}>
            {t('resultPage.salaryUz', { salary: profession.salary_uz.toLocaleString() })}
          </div>
          <div className={styles.salaryInfo2}>
            {t('resultPage.salaryWorld', { salary: profession.salary_wrld })}
          </div>
          <div className={styles.buttonGroup}>
            <button 
              className={`${styles.professionButton} ${styles.secondaryButton}`}
              onClick={() => setSelectedProfession(profession)}
            >
              {t('resultPage.moreDetails')}
            </button>
            <a 
              href={profession.courses[0].link}
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.professionButton} ${styles.primaryButton}`}
            >
              {t('resultPage.becomeSpecialist')}
            </a>
          </div>
        </div>
      ))
    );
  };

  if (!quizResults?.recommended_profiles?.length) {
    return (
      <div className={styles.resultContainer}>
        <header className={styles.header}>
          <img src={secondaryLogoUrl} alt={t('common.universityLogo')} className={styles.logoImage} />
        </header>
        <div className={styles.noResults}>
          <h1>{t('resultPage.noResults')}</h1>
        </div>
        <footer className={styles.footer}>
          <div className={styles.poweredBy}>
            {t('common.poweredBy')}
            <img src="/logo192.webp" alt="WorkXplorer logo" className={styles.workxplorerIcon} />
          </div>
        </footer>
      </div>
    );
  }

  return (
    <div className={styles.resultContainer}>
      <header 
        className={styles.header}
        style={{ backgroundColor: secondaryColor }}
      >
        <a href={`${universityData?.universityWebsite}`} target="_blank" rel="noopener noreferrer">
          <img src={secondaryLogoUrl} alt={t('common.universityLogo')} className={styles.logoImage} />
        </a>
        <h1 className={styles.mainTitle}>
          {t('resultPage.congratulations', {
            name: quizResults.name,
            profile: quizResults.recommended_profiles[0].name
          })}
        </h1>
      </header>

      <main className={styles.mainContent}>
        <div className={styles.contentLayout}>
          <div className={styles.leftColumn}>
            <section>
              <h2 className={styles.sectionTitle}>{t('resultPage.results')}</h2>
              <div className={styles.profileScoresGrid}>
                {getProfileResults().map((profile, index) => (
                  <div
                    key={profile.name}
                    className={styles.profileScoreCard}
                    style={{
                      backgroundColor: secondaryColor,
                      opacity: 1 - (index * 0.15)
                    }}
                  >
                    <span className={styles.profileScoreText}>
                      {t('resultPage.scoreText', {
                        profile: profile.name,
                        score: profile.score.toFixed(1) 
                      })}
                    </span>
                  </div>
                ))}
              </div>
            </section>

            <section className={styles.professionsSection}>
              <h2 className={styles.sectionTitle}>{t('resultPage.recommendedProfessions')}</h2>
              <div className={styles.professionsGrid}>
                {renderProfessionCards()}
              </div>
            </section>
          </div>

          <div className={styles.rightColumn}>
            <section>
              <h2 className={styles.sectionTitle}>{t('resultPage.profileMapTitle')}</h2>
              <div className={styles.chartContainer}>
                <ResponsiveRadarChart 
                  data={generateChartData()} 
                  secondaryColor={secondaryColor}
                />
              </div>
              <div className={styles.profileDescription}>
                <p>
                  {`${quizResults.recommended_profiles[0].name} - ${quizResults.recommended_profiles[0].description}`}
                </p>
              </div>
            </section>
          </div>
        </div>
      </main>

      <footer className={styles.footer}>
        <div className={styles.poweredBy}>
        <a href='https://workxplorer.uz'>
          {t('common.poweredBy')}
          <img src="/logo192.webp" alt="WorkXplorer logo" className={styles.workxplorerIcon} />
          </a>
        </div>
      </footer>

      {selectedProfession && (
        <ProfessionModal
          profession={selectedProfession}
          onClose={() => setSelectedProfession(null)}
          universityData={universityData}
        />
      )}
    </div>
  );
};

export default ResultPage;