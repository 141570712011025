// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import QuizWrapper from './components/UniversityQuizApp/QuizWrapper';
import AdminDashboard from './components/UniversityQuizApp/AdminDashboard';
import Login from './components/UniversityQuizApp/Login';
import { getSubdomain } from './utils/helpers';
import styles from './App.module.css';

// Protected Route with navigation
const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isChecking, setIsChecking] = React.useState(true);

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        // Store current path for redirect after login
        localStorage.setItem('redirectAfterLogin', window.location.pathname);
        navigate('/admin-dashboard', { state: { showLogin: true } });
      }
      setIsChecking(false);
    };
    
    checkAuth();
  }, [navigate]);

  if (isChecking) {
    return null; // or a loading spinner
  }

  return localStorage.getItem('access_token') ? children : null;
};

function App() {
  const subdomain = getSubdomain();

  return (
    <Router>
      <div className={styles.app}>
        <Routes>
          {!subdomain && (
            <>
              <Route path="/" element={<LandingPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
          {subdomain && (
            <>
              <Route path="/" element={<QuizWrapper />} />
              <Route 
                path="/admin-dashboard"
                element={
                  localStorage.getItem('access_token') ? (
                    <ProtectedRoute>
                      <AdminDashboard />
                    </ProtectedRoute>
                  ) : (
                    <Login />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;