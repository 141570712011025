// Login.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../utils/api';
import styles from '../../styles/UniversityQuizAppStyles/Login.module.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const response = await login(email, password);
      console.log('Full response:', response); // Debug log
      
      // Check if we have a direct access property in response
      if (response.access) {
        localStorage.setItem('access_token', response.access);
        window.location.href = '/admin-dashboard';
        return;
      }
      
      // If response has a data property
      if (response.data?.access) {
        localStorage.setItem('access_token', response.data.access);
        window.location.href = '/admin-dashboard';
        return;
      }

      // If response is the token string directly
      if (typeof response === 'string') {
        localStorage.setItem('access_token', response);
        window.location.href = '/admin-dashboard';
        return;
      }

      // If we reach here, we couldn't find the token
      console.error('Token structure:', response);
      throw new Error('No access token received');
      
    } catch (err) {
      console.error('Login error:', err);
      setError('Invalid credentials. Please try again.');
      localStorage.removeItem('access_token');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.loginContainer}>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p className={styles.errorMessage}>{error}</p>}
        <button type="submit" className={styles.loginButton} disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default Login;