import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import styles from '../styles/LandingPage.module.css';

const LandingPage = () => {
  const { t, i18n } = useTranslation();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('students');

  const changeLanguage = (lng) => {
    setIsTransitioning(true);
    setTimeout(() => {
      i18n.changeLanguage(lng);
      setIsTransitioning(false);
    }, 300);
  };

  const renderFeatures = () => {
    if (activeSection === 'companies') {
      return (
        <div className={styles.features}>
          <div className={styles.feature}>
            <h3>{t('landing.companies.talent.title')}</h3>
            <p>{t('landing.companies.talent.description')}</p>
          </div>
          <div className={styles.feature}>
            <h3>{t('landing.companies.assessment.title')}</h3>
            <p>{t('landing.companies.assessment.description')}</p>
          </div>
          <div className={styles.feature}>
            <h3>{t('landing.companies.analytics.title')}</h3>
            <p>{t('landing.companies.analytics.description')}</p>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.features}>
        <div className={styles.feature}>
          <h3>{t('landing.features.assessment.title')}</h3>
          <p>{t('landing.features.assessment.description')}</p>
        </div>
        <div className={styles.feature}>
          <h3>{t('landing.features.guidance.title')}</h3>
          <p>{t('landing.features.guidance.description')}</p>
        </div>
        <div className={styles.feature}>
          <h3>{t('landing.features.matching.title')}</h3>
          <p>{t('landing.features.matching.description')}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <html lang={i18n.language} />
        <title>{t('meta.title')}</title>
        <meta name="description" content={t('meta.description')} />
        <meta name="keywords" content={t('meta.keywords')} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://workxplorer.uz" />
        <meta property="og:title" content={t('meta.title')} />
        <meta property="og:description" content={t('meta.description')} />
        <meta property="og:image" content="https://workxplorer.uz/white_logo192.webp" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://workxplorer.uz" />
      </Helmet>

      <div className={styles.container}>
        <section className={styles.hero}>
          <div className={styles.language_selector}>
            <div className={`${styles.lang_dropdown} ${isDropdownOpen ? styles.open : ''}`}>
              <button 
                className={styles.lang_selected}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {i18n.language.toUpperCase()}
              </button>
              <div className={styles.lang_options}>
                <button 
                  className={`${styles.lang_option} ${i18n.language === 'en' ? styles.active : ''}`}
                  onClick={() => {
                    changeLanguage('en');
                    setIsDropdownOpen(false);
                  }}
                >
                  English
                </button>
                <button 
                  className={`${styles.lang_option} ${i18n.language === 'ru' ? styles.active : ''}`}
                  onClick={() => {
                    changeLanguage('ru');
                    setIsDropdownOpen(false);
                  }}
                >
                  Русский
                </button>
                <button 
                  className={`${styles.lang_option} ${i18n.language === 'uz' ? styles.active : ''}`}
                  onClick={() => {
                    changeLanguage('uz');
                    setIsDropdownOpen(false);
                  }}
                >
                  O'zbekcha
                </button>
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.logo_container}>
              <img 
                src="/white_logo192.webp"
                alt="WorkXplorer Logo" 
                className={styles.logo}
              />
            </div>
            
            <h1 className={styles.title}>WorkXplorer</h1>
            
            <p className={styles.description}>
              {t('landing.description')}
            </p>

            <div className={styles.coming_soon}>
              <span className={styles.text} style={{ opacity: isTransitioning ? 0 : 1 }}>
                {t('landing.comingSoon')}
              </span>
            </div>

            <div className={styles.audience_buttons}>
              <button
                className={`${styles.audience_button} ${activeSection === 'students' ? styles.active : ''}`}
                onClick={() => setActiveSection('students')}
              >
                {t('landing.buttons.forStudents')}
              </button>
              <button
                className={`${styles.audience_button} ${activeSection === 'companies' ? styles.active : ''}`}
                onClick={() => setActiveSection('companies')}
              >
                {t('landing.buttons.forCompanies')}
              </button>
            </div>

            {renderFeatures()}
          </div>
        </section>

        <div className={styles.construction_badge}>
          Site under development 🚧
        </div>

        <footer className={styles.footer}>
          <div className={styles.footer_content}>
            <nav className={styles.footer_links} aria-label="Footer Navigation">
              <a href="/contact" className={styles.link}>{t('footer.contact')}</a>
            </nav>
            <div className={styles.copyright}>
              © {new Date().getFullYear()} {t('footer.legalname')} {t('footer.copyright')}
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default LandingPage;